import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/4bcc10c8/src/templates/PageWrapper.js";
import { graphql } from 'gatsby';
import { Button } from '../components/atoms';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`I’m a digital designer with a strong interest in new technologies and their influences in our everyday life`}</h2>
    <h3>{`Standard Bio`}</h3>
    <p>{`During my teenage years I co-founded `}<strong parentName="p">{`GameSoul`}</strong>{`, an Italian virtual community about the art of making amateur video games, with more than a thousand members. I got really excited about the idea of creating a digital space where people can share their own interests. And since then, I’ve been tinkering with web technologies and digital interfaces.`}</p>
    <p>{`I taught myself the basic principles of web design and development and I started working as `}<strong parentName="p">{`web designer`}</strong>{` and `}<strong parentName="p">{`front-end developer`}</strong>{` in a local company first (2005–2008), then in a Milan-based company (2008-2009) after my high school diploma.`}</p>
    <p>{`I soon realised that a serious design process can make a huge difference. Therefore I enrolled in the `}<strong parentName="p">{`Communication Design`}</strong>{` course at `}<a parentName="p" {...{
        "href": "http://www.design.polimi.it/?lang=en"
      }}>{`Politecnico di Milano`}</a>{`, where I broadened my knowledge of typography, branding, editorial design and design history.`}</p>
    <p>{`Then I spent three years as Product Designer at `}<a parentName="p" {...{
        "href": "http://www.motork.io"
      }}>{`MotorK`}</a>{` with key responsibilities in the design of `}<a parentName="p" {...{
        "href": "http://www.drivek.it"
      }}>{`DriveK`}</a>{` products and services. `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/damianoplebani/"
      }}>{`Learn more about this experience`}</a>{`.`}</p>
    <p>{`I'm currently working as UX Engineer at `}<a parentName="p" {...{
        "href": "https://sketchin.ch"
      }}>{`Sketchin`}</a>{`, a strategic design firm based in Lugano, Switzerland.`}</p>
    <h3>{`Technicalities`}</h3>
    <p><strong parentName="p">{`Web`}</strong>{` I have solid experience in writing `}<em parentName="p">{`HTML`}</em>{` and `}<em parentName="p">{`CSS`}</em>{`. Basic knowledge of `}<em parentName="p">{`Javascript`}</em>{` and relative frameworks (`}<em parentName="p">{`React`}</em>{` and `}<em parentName="p">{`Vue`}</em>{`).`}</p>
    <p><strong parentName="p">{`User Interface Design`}</strong>{` I use `}<em parentName="p">{`Sketch`}</em>{` to design user interfaces. I’m currently interested in learning `}<em parentName="p">{`Framer`}</em>{` for interactive prototypes.`}</p>
    <p><strong parentName="p">{`Graphic Design`}</strong>{` I have a good knowledge of `}<em parentName="p">{`InDesign`}</em>{` and `}<em parentName="p">{`Illustrator`}</em>{`. During my Bachelor studies I learnt how to deal with small digital print operators.`}</p>
    <p><strong parentName="p">{`Programming`}</strong>{` I’m able to understand basic structures of common programming languages, therefore I can collaborate with engineers and developers with ease.`}</p>
    <p><strong parentName="p">{`Toolbox`}</strong>{` I use `}<em parentName="p">{`VS Code`}</em>{` for coding and `}<em parentName="p">{`Webpack`}</em>{` as module bundler. I have a good knowledge of `}<em parentName="p">{`Git`}</em>{` and `}<em parentName="p">{`Command Line`}</em>{`.`}</p>
    <h3>{`Side-project(s)`}</h3>
    <p><a parentName="p" {...{
        "href": "http://instagram.com/typecakes"
      }}>{`Typecakes (Work in Progress)`}</a>{`
: A birthday cake, a typeface and a lot of dedication`}</p>
    <h3>{`Elsewhere`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/dmnplb"
        }}>{`My thoughts on Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/stars/dmnplb"
        }}>{`My starred repositories on Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.last.fm/user/damianoplebani"
        }}>{`My listens on LastFm`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/damianoplebani/likes"
        }}>{`My likes on Vimeo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://letterboxd.com/dmnplb/stats/"
        }}>{`My movies' taste on Letterboxd`}</a></li>
    </ul>
    <h3>{`Interested?`}</h3>
    <p>{`Don’t hesitate to write me at `}<a parentName="p" {...{
        "href": "mailto:hello@damianoplebani.com"
      }}>{`hello@damianoplebani.com`}</a></p>
    <section className="writing colophon">
  <p>Designed and coded in Europe<br />Last update: December 2018</p>
    </section>
    <Button to="/" mdxType="Button">Test Button</Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      